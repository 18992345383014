.viewLotsContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.viewLotsContentScroller {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.viewLotsContentPaper {
    margin: 15px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    
}

.viewLotsContentPaper h1 {
    margin-top: 0px;
}

.viewLotsHeaderButtonHolder {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
}

.viewLotsContentBody {
    display: block;
    width: 100%;
}

.viewLotsLotCard {
    padding: 15px;
    margin: 15px;
    width: 440px;
    max-width: calc(33% - 30px);
    height: 200px;
    float: left;
    display: flex;
    flex-direction: column;
    overflow: hidden;

}

.viewLotsLotCardHeader {
    margin-top: 0px;
    margin-bottom: 8px;
    height: 50px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Display only two lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5; /* Adjust line height as needed */
    font-weight: bold;
    font-size: 16px;


}

.viewLotsLotCard h5 {
    margin-top: 0px;
    margin-bottom: 5px;
    height: 25px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.viewLotsLotCard p {
    margin-top: 0px;
    margin-bottom: 5px;
}

.viewLotsLotCardButtonHolder {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

}

@media only screen and (max-width : 900px) {
    
    .viewLotsContentPaper {
        padding: 10px;
    }    

    .viewLotsLotCard {
        width: 100%;
        max-width: calc(100% - 60px);
        margin-top: 0px;
    }
}


