.profileImg {
    height: 40px;
    width: 40px;
    border-radius: 40px;
}

.authInterfaceLoginHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 570px;
}

.authInterfaceLogin {
    padding: 25px;
    display: flex;
    flex-direction: column;
}

.authInterfaceLoginEmailActionHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.authInterfaceCreateAccount {
    padding: 25px;
    display: flex;
    flex-direction: column;

}


@media (max-width: 850px){

    .authInterfaceLoginHolder {
        width: 100%;
        height: 100%;
    }

    .authInterfaceLoginEmailActionHolder {
        display: flex;
        flex-direction: column;

    }

}