.exportImageryManagerContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.exportImageryManagerContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1500px;
    max-width: 100%;
    margin: auto;

}

.exportImageryManagerContentPaper {
    margin: 15px;
    padding: 30px;
}

.exportImageryManagerContentPaper h1 {
    margin-top: 0px;
}
