.getStartedContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.getStartedContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    overflow: auto;
    width: 1000px;
    max-width: 100%;
    margin: auto;


}

.getStartedContentPaper {
    margin: 15px;
    padding: 30px;
}

.getStartedContentPaper h1 {
    margin-top: 0px;
}

.getStartedContentPaper p {
    line-height: 1.25em;
    font-weight: 500;
    color: #000000;
    font-size: 16px;
}


@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {


}




