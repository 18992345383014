.accountContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.accountContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    overflow: auto;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.accountContentPaper {
    margin: 15px;
    padding: 30px;
}

.accountContentPaper h1 {
    margin-top: 0px;
}

.accountInfoArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
}

.accountInfoProfileImg {
    height: 50px;
    width: 50px;
    background-color: #eeeeee;
    border-radius: 100px;
    align-content: center;
}

.accountInfoAreaTextHolder {
    display: block;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
    width: calc(100% - 120px);
}

.accountInfoAreaTextHolder {
    margin-top: 0px;
    margin-bottom: 5px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accountInfoAreaTextHolder h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accountInfoAreaTextHolder h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accountInfoAreaTextHolder p {
    margin: 0px;
    font-size: 12px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.accountContentArea {
    padding-top: 15px;
}

@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {
    .accountInfoProfileImg {
        height: 50px;
        width: 50px;
    }

    .accountInfoAreaTextHolder {
        margin: 0px;
    }

    .accountInfoAreaTextHolder h2 {
        font-size: 16px;
        margin: 0px;
    }

    .accountInfoAreaTextHolder h4 {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .accountInfoAreaTextHolder p {
        font-size: 12px;
        margin: 0px;
        font-weight: bold;
    }

}




