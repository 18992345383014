.checkoutContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.checkoutContentScroller {
    flex: 1;
    overflow: auto;

}

.checkoutContentHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    width: 1000px;
    max-width: 100%;
    height: 100%;
    margin: auto;
}

.checkoutContentHeader {
    margin: 15px;
    padding: 30px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.checkoutContentPaper {
    margin: 15px;
    padding: 30px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
}

.checkoutContentPaper .sectionHeader h3 {
    flex: 1;
    text-align: right;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

}

.checkoutContentPaperHeaderArea {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-height: 40px;
    margin-bottom: 0px;
}

.checkoutContentPaperHeaderArea h2 {
    margin-bottom: 0px;
}

.checkoutContentPaperHeaderArea button {
    padding: 0px;
}

.checkoutTotal {
    margin-top: 0px;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
}

.checkoutContentPaper h1 {
    margin-top: 0px;
}

.checkoutMapContainer {
    flex: 1;
    width: calc(1000px - 30px);
    max-width: 100%;
    border-radius: 3px;
    overflow: hidden;
}

.checkoutContentPaper h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 20px;
}

.checkoutContentPaper h2 span {
    font-weight: bold;
    font-size: 20px;
}

.checkoutMapButtonHolder {
    padding-top: 20px;
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.checkoutMapButtonHolder h3 {
    padding-left: 15px;
    text-align: center;
}

.checkoutMapButtonHolder h3 span {
    white-space: nowrap;
}

.checkoutOptionsArea {
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: auto;
    border-radius: 10px;
    background-color: #eeeeee;
    box-shadow: rgba(50, 50, 93, 0.125) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.15) 0px 18px 36px -18px inset;
}

.viewLotCheckoutOptionsArea {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 10px;
    background-color: #eeeeee;
    box-shadow: rgba(50, 50, 93, 0.125) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.15) 0px 18px 36px -18px inset;
}

.checkoutOption {
    flex: 1;
    margin: 10px;
    padding: 15px;
    min-width: 200px;
    max-width: 400px;
    border-radius: 10px;
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: #dddddd;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    cursor: pointer;

}

.checkoutOption h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
}

.checkoutOption h3 {
    flex: 1;
    font-weight: 200;
    text-align: center;
    margin-bottom: 0px;
    font-size: 14px;
}

.checkoutOption h2 {
    font-weight: 200;
    text-align: center;
    font-size: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutOption h2 span {
    font-size: 20px;
    margin-left: 5px;
    font-weight: 100;
}

.checkoutOption h4 {
    height: 14px;
    font-weight: bold;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 40px;
    font-size: 14px;
}

.priceSelectorTr {
    cursor: pointer;
}

.checkoutPaymentOptionsArea {
    box-shadow: rgba(50, 50, 93, 0.125) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.15) 0px 18px 36px -18px inset;
    border-radius: 5px;
    overflow: hidden;
    overflow-x: auto;
}

.checkoutPaymentOptionsAreaHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 250px;
    padding: 30px 0px 0px 30px;
}

.checkoutExplanationText {
    font-size: 14px;
    font-weight: 400;
    color: #5a5a5a;
    margin-top: 0px;

}

.checkoutContentNavigationHolder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 15px;
    margin-bottom: 30px;
}



.sectionHeader h3 {
    margin: 0;
    line-height: 16px;
    text-align: right;
}

.sectionHeader h3 span {
    font-weight: bold;
    font-size: 16px !important;
    text-align: right;

}

@media only screen and (max-width : 900px) {

    .checkoutContentHeader {
        padding: 10px;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .checkoutContentPaper {
        padding: 10px;

    }

    .checkoutContentPaperChoose {
        flex: 100;
    }

    .checkoutContentPaperHeaderArea {
        flex-direction: column;
        max-height: 120px;
        margin-bottom: 10px;
        align-items: flex-start;
    }

    .sectionHeader h3 span {
        font-weight: bold;
        font-size: 16px !important;
        text-align: right;
    }

    .checkoutContentPaper .sectionHeader {
        font-size: 20px;
    }

    .checkoutContentPaper p {
        font-size: 12px;
    }

    .checkoutMapButtonHolder {
        padding-top: 10px;
        height: 40px;

    }

    .checkoutContentNavigationHolder {
        margin-bottom: 0px;
    }

    .checkoutContentNavigationHolderSliderElement {
        display: none;
    }

    .checkoutOptionsArea {
        flex-direction: column;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        min-height: 300px;
        max-height: calc(100vh - 350px);
        display: block;
        box-shadow: rgba(50, 50, 93, 0.125) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.15) 0px 18px 36px -18px inset;
        border-radius: 5px;
    }
    
    .checkoutOption {
        background-color: #ffffff;
        padding: 10px;
    }

    .checkoutOption h1 {
        margin-bottom: 0px;

    }
    
    .checkoutOption h3 {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 14px;
    }

    
    .checkoutOption h4 {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    
    .checkoutContentHolder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        width: 100%;
        max-width: 100%;
        margin: auto;
    }

    .checkoutPaymentOptionsArea {
        overflow-y: auto;
        flex: 1;
        min-height: 200px;
        max-height: calc(100vh - 480px);
    }

    .checkoutPaymentOptionsAreaHolder {
        flex-direction: column;
        padding: 10px;
        padding-bottom: 400px;
        width: calc(100% - 20px) !important;
    }

    

    
}

