.findAreaPageContent {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    width: 100%;

}

.findAreaHeaderArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
  background-color: #f9a825 !important;
  border-radius: 0 !important;
  position: relative;
  z-index: 2;

}

.findAreaHeaderArea h2 {
  flex: 1
}

.findAreaContent {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex: 1;
}

.findAreaScrollArea {
  width: 300px;
  display: flex;
  flex-direction: column;
  z-index: 1;

}

.findAreaScrollAreaSubScroll {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: #bdbdbd !important;
  width: 100%;
}

.findAreaContentMapContainer {
  flex: 1;
}


.findAreaRectangleInterface {
  margin: 15px;
  margin-bottom: 0;
  padding: 10px;
  float: left;
}

.findAreaRectangleInterface:hover {
  cursor: pointer;
}

.aoiRectangleInterfaceHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.aoiRectangleInterfaceHeaderTitle {
  flex: 1;
  align-items: center;
  display: flex;
}

.aoiRectangleInterfaceHeaderTitle h5 {
  float: left;
  margin: 0;
}

.aoiRectangleInterfaceColor {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin-left: 10px;
  float: left;
}

.aoiRectangleInterfaceContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.aoiRectangleInterfaceContentTitle {
  margin: 0;
  font-size: 14px;
}


.aoiRectangleInterfaceContentValue {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}
 
.aoiRectangleInterfaceActions {
  display: flex;
  justify-content: flex-end;
 
}