.legalContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.legalContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.legalContentPaper {
    margin: 15px;
    padding: 30px;
}

.legalContentPaper h1 {
    margin-top: 15px;
    margin-bottom: 5px;
}

.legalContentPaper h2 {
    margin-top: 25px;
    margin-bottom: 5px;
}

.legalContentPaper h5 {
    margin-top: 0px;
    margin-bottom: 5px;
}


@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {
    
    

}




