.welcomeContent {
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Roboto, Arial, Helvetica, sans-serif;
    background-color: #000000;
    position: relative;
}



.backgroundImg {
    width: 100vw;
    height: 177vw;
    position: absolute;
    z-index: 1;
    background-color: #000000;
    transform: translateY(0);
    object-fit: cover;
    transition: all 0.5s linear;
}


.earthImg {
    width: 200vw;    
    height: 177vw;
}

.welcomeNavigationButtons {
    position: absolute;
    z-index: 2;
    bottom: 5vh;
    width: 100vw;
    display: flex;
    justify-content: center;


}

.welcomeContentScroller {
    width: 100vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* transition: all 0.5s linear; */
    z-index: 3;
}

.welcomeContentScroller::-webkit-scrollbar {
    display: none;
}



.contentHolder {
    width: 800px;
    display: block;
    padding: 0px;
    margin-top: 0;
    transition: all 0.5s linear;
}

.navigationButtonHolder {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

}

.section01 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section01 .welcomeLogo {
    
}

.section01 h1 {
    font-size: 100px;
    color: white;
    text-align: left;
    width: 100%;
    margin: 0px;
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);


}

.section01 h2 {
    font-size: 35px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    margin-bottom: 10px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
    font-weight: 500;
}

.section01 div p {
    font-size: 30px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: 100;
    margin-bottom: 10px;
}


.section02 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section02 h1 {
    font-size: 80px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.section02 h2 {
    font-size: 50px;
    color: white;
    text-align: left;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: 100;
    margin-bottom: 10px;
}


.section02 div p {
    font-size: 16px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: 500;
    margin-bottom: 10px;
}



.section03 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section03 h1 {
    font-size: 80px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    margin-bottom: 30px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);

}

.section03 h4 {
    font-size: 25px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    margin-bottom: 30px;
    font-weight: 500;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);

}


.section03 h3 {
    font-size: 30px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    margin-bottom: 30px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);

}


.section03 p {
    font-size: 30px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: 100;
    margin-bottom: 10px;
}




.section04 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    flex-direction: column;
    text-align: left;

}

.section04 h1 {
    font-size: 80px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    margin-bottom: 30px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);


}

.section04 .priceCompareHolder {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.section04 .priceCompareLegendHolder {
    padding: 15px;
    width: 250px;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 15px;
    margin-left: -35px;

}

.section04 .priceCompareLegend h3 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 5px;
}

.section04 .barChart {
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    width: calc(100% - 80px);
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold !important;

}

.section04 .barChart h3 {
    font-size: 25px;
    color: black;
    text-align: center;
    width: 100%;
    margin: 0px;
    font-weight: bold;
}

.section04 svg text {
    font-weight: bold !important;
    font-size: 12px !important;
}

.section04 svg text.MuiBarLabel-root {
    font-size: 14px !important;

}

.section04 svg .MuiChartsAxis-bottom text {
    font-size: 14px !important;

}

.section05 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section05 h1 {
    font-size: 80px;
    color: white;
    text-align: left;
    width: 100%;
    margin: 0px;
    margin-bottom: 15px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.section05 h2 {
    font-size: 25px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: bold;
    margin-bottom: 30px;
}

.section05 .resolutionCompareHolder {
    width: 800px;
    height: 400px;
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;   
    /* transition: all .1s linear;    */
    position: relative; 

}

.section05 .resolutionCompareHolder img {
    filter: brightness(1.3) contrast(1) saturate(.8);
}


.section05 .resolutionCompareHolderGetStartedOverlay {
    position: absolute;
    width: 800px;
    height: 400px;
    top: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    border-radius: 10px;
    cursor: pointer;
    /* transition: opacity 0.3s ease-in-out; */
}


.section05 .resolutionCompareHolderGetStartedOverlay h3 {
    font-size: 60px;
    color: white;
    text-align: center;
    width: 100%;
    margin: 0px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.section05 .resolutionCompareHolderGetStartedOverlay p {
    font-size: 30px;
    color: #ffffff;
    text-align: center;
    font-weight: 500;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}


.section05 .resolutionCompare {
    flex: 1;
    overflow: hidden;
    position: absolute;    
    height: 400px;
    width: 760px;
    margin: 20px;
    border-radius: 5px;
}


.section05 .resolutionComparePhotoHolder {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* transition: all 1s ease-in-out; */
    flex: 1;
}

.section05 .resolutionCompareZoomHolder {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 70px;
}

.section05 .resolutionComparePhoto {
    width: 760px;
    max-width: 760px;
    height: 100%;
    object-fit: cover;

}

.section05 .resolutionCompareLabels {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 420px;
    padding: 20px;
}

.section06 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section06 h1 {
    font-size: 80px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.changeDetectionHolder {
    width: 800px;
    height: 510px;
    margin-top: 10px;
    border-radius: 10px !important;
    overflow: hidden;   
    /* transition: all .1s linear;    */
    position: relative; 
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.changeDetectionImageHolder {
    flex: 1;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: #000000;
}

.changeDetectionImageHolder h3 {
    position: absolute;
    z-index: 2;
    margin: 0px;
    top: 10px;
    left: 0px;
    width: 222px;
    text-align: right;
    font-size: 20px;
    color: white;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.changeDetectionImage {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    filter: brightness(1.8) contrast(1.2) saturate(1.3);
    transition: all .2s linear;
    background-color: #000000;
    object-fit: cover;
}

.changeDetectionSliderHolder {
    height: 50px;
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 0px !important;
}

.changeDetectionSliderHolder span .MuiSlider-track {
    height: 5px;
}

.changeDetectionSliderHolder span .MuiSlider-markLabel {
    font-weight: bold !important;
    color: #0b0b0b !important;
}

.section07 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section07 h1 {
    font-size: 60px;
    color: white;
    text-align: left;
    margin-bottom: 10px;
    width: 100%;
    margin: 0px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.section07 .missionDemoHolder {
    width: 100%;
    height: 480px;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.section07 .missionDemoHolder .missionDemoElement {
    width: 100%;
    height: 150px;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    font-size: 45px;    
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s linear;
    filter: saturate(.8) contrast(1) !important;

}

.section07 .missionDemoHolder .missionDemoElement:hover {
    filter: saturate(1.4) contrast(1.1) !important;
}

.section07 .missionDemoHolder .missionDemoElement h3 {
    text-align: center;
    margin: 0px;
    color: #ffffff;
    z-index: 20;
}
    

.section07 .missionDemoHolder .missionDemoElement img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    
}


.section08 {
    width: 100%;
    min-height: 0px;
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    flex-direction: column;
    text-align: left;
}

.section08 h1 {
    font-size: 60px;
    color: white;
    text-align: left;
    margin-bottom: 20px;
    width: 100%;
    margin: 0px;
    font-weight: bold;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
}

.section08 h2 {
    font-size: 30px;
    color: white;
    text-align: left;
    margin-bottom: 1rem;
    width: 100%;
    margin: 0px;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 1);
    font-weight: 500;
    margin-bottom: 30px;
}

.section08ButtonHolder {
    padding-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

}

.section08ButtonZipHolder {
    display: flex;
    margin-bottom: 10px;
    flex-direction: row;
    align-items: flex-start;
    align-items: center;
    padding: 20px !important;
    width: calc(100% - 40px);
}

.section08ButtonTextInput {
    margin-bottom: 0px !important;
    flex: 1;
}

.section08SubmitButton {
    margin: 0px !important;
    margin-left: 20px !important;
    width: 200px;
    height: 100%;
}


@media (max-width: 850px){
    
    .contentHolder {
        width: calc(100% - 30px);
    }

    .section01 h1 {
        font-size: 60px;
    }

    .section01 div p {
        font-size: 20px;
        font-weight: 500;
    }
    

    .section03 h1 {
        font-size: 40px;
    }

    .section03 p {
        font-size: 20px;
        font-weight: 500;
    }

    .section04 p {
        font-size: 20px;
    }

    .section04 h1 {
        font-size: 40px;
    }

    .section04 .priceCompareHolder h3 {
        padding-top: 10px;
    }

    .section04 .barChart {
        width: calc(100% + 20px);
        padding: 0px;
        margin-top: -20px;
    }

    .section04 svg .MuiChartsAxis-bottom text {
        font-size: 10px !important;
    
    }

    .section04 .priceCompareLegendHolder {
        display: none;
    }

    .section05 h1 {
        font-size: 40px;
    }

    .section05 .resolutionCompareHolder {
        width: 100%;
        height: auto;
    }

    .section05 .resolutionCompareHolderGetStartedOverlay {
        width: 100%;
        height: 100%;
    }

    .section05 .resolutionCompareHolderGetStartedOverlay h3 {
        font-size: 40px;
    }

    .section05 .resolutionCompareHolderGetStartedOverlay p {
        font-size: 20px;
    }

    .section06 h1 {
        font-size: 40px;
    }

    .section06 .changeDetectionHolder {
        width: 100%;
        height: 350px;
    }

    .section06 .changeDetectionHolder .changeDetectionImageHolder .changeDetectionImage {
        width: 100%;
        height: 100%;
    }

    .section06 .changeDetectionSliderHolder {
        padding-top: 0px;
        height: 55px;
    }

    .section07 h1 {
        font-size: 40px;
    }

    .section07 .missionDemoHolder {
        height: 320px;
    }

    .section07 .missionDemoHolder .missionDemoElement {
        height: 100px;
    }

    .section07 .missionDemoHolder .missionDemoElement h3 {
        font-size: 25px;
    }

    
    .section08ButtonTextInput {
        width: unset;
    }

    .section08SubmitButton {
        width: unset;        
    }

    .section08 h1 {
        font-size: 40px;
    }
}

