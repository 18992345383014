.adminDashboardContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.adminDashboardContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.adminDashboardContentPaper {
    margin: 15px;
    padding: 30px;
}

.adminDashboardContentPaper h1 {
    margin-top: 0px;
}

.earthEngineActiveTasks {
    margin-top: 15px;
    margin-bottom: 15px;
    display: block;
}