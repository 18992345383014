.gifPageContent {
    display: flex;
    flex-direction: row !important;
    height: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
    
}

.actionTab {
    display: block;
    position: absolute;
    bottom: 30px;
    left: 10px;
    border-radius: 3px;
    background-color: #ffffff;
    padding: 10px;
}





