.inviteAdminContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.inviteAdminContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.inviteAdminContentPaper {
    margin: 15px;
    padding: 30px;
}

.inviteAdminContentPaper h1 {
    margin-top: 0px;
}

.inviteAdminElementHolder {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 25px;
    
}

.inviteAdminElementHolderLogin {

}

.inviteAdminElementHolderLogin h3 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.inviteAdminElement {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 30px;
    margin: 15px;
    min-width: 150px;
    min-height: 275px;
    
}

.inviteAdminElement h2 {
    margin-top: 0px;
    margin-bottom: 10px;
}

.inviteAdminElement h4 {
    margin-bottom: 0px;
    font-weight: 500;
}

.inviteAdminElement h5 {
    margin-top: 0px;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 5px;
    height: 25px;
}

.inviteAdminElement ul {
    font-size: 14px;
    line-height: 1.5em;
    font-weight: 400;
    padding-left: 15px;
}

.inviteAdminElement div {
    flex: 1;
}

.inviteAdminElement h1 {
    font-size: 25px;
    margin: 0;
    text-align: center;
}

.inviteAdminElement h1 span {
    font-size: 16px;
    font-weight: 400;
    color: #555555;
}

.inviteAdminSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inviteAdminSelector span {
    flex: 1;
}

.inviteAdminSelector h1 {
    margin-right: 20px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {
    
    .inviteAdminElementHolder {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        

        display: flex;
        overflow-x: hidden;
        width: calc(100% - 30px);
        border-radius: 0px;
        margin: 0px;
        padding: 15px;
        padding-top: 0px;
        box-shadow: none;

    }
    
    .inviteAdminElement {
        min-height: 0px;
        width: calc(100% - 60px);
        margin: 0px;
        margin-bottom: 15px;
        
    }

    .inviteAdminElement ul {
        margin-top: 0px;
    }

}




