.HomeContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.HeroImg {
    width: 100vw;
    height: calc(80vh - 60px);    
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #424242 !important;
    position: relative;

}

.heroImgLogo {
    height: 14em;
    margin-right: 1em;
    position: absolute;
}

.heroImgLogoBG {
    height: 14em;
    margin-right: 1em;
    filter: brightness(0%) blur(5px);
    opacity: 1;
    position: absolute;
    margin-left: 5px;
}


.HeroImg div {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}

.HeroImg div div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.HeroImg div div h1 {
    margin: 0;
    color: #ffffff;
    text-shadow: 2px 2px 8px #000000;
    font-size: 6em;
    font-weight: bold;
    line-height: 1.2em;
}

.HeroImg div div  h3 {
    margin-top: 0px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 0px;
    color: #ffffff;
    text-shadow: 2px 2px 8px #000000;
    font-size: 2.6em;
}

.howItWorks {
    width: 90vw;
    display: flex;
    justify-content: center;
    position: relative;
}

.howItWorks h2 {
    color: #212121;
    margin: 0;
    margin-bottom: 1em;
}

.howItWorksContent {  
    padding: 2em;  
    height: 30em;
    width: 90em;
    max-width: 95vw;
    display: flex;
    flex-direction: row;    
    overflow: hidden;
    background-color: #ffffff;
}

.howItWorksDataDiv {
    width: 400px;
    margin-right: 2em;
    display: flex;
    flex-direction: column;

}

.howItWorksImgArea {
    flex: 1;
    border-radius: 1em;
    border-bottom-left-radius: 6em;
    border-top-right-radius: 6em;
    overflow: hidden;
    position: absolute;    
    height: 100%;
    width: 100%;
}


.howItWorksImgAreaShadow {
    flex: 1;
    border-radius: 1em;
    border-bottom-left-radius: 6em;
    border-top-right-radius: 6em;
    overflow: hidden;
    position: absolute;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, .6) 0px 18px 36px -18px inset;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .65);
    opacity: 1;
    transition: .5s;
}

.howItWorksImgAreaShadow:hover {
    opacity: .9;
}

.howItWorksImgAreaShadow h1 {
    color: #ffffff;
}


.howItWorksPhotoHolder {
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all .5s ease-in-out;
}

.howItWorksPhoto {
    width: calc(90em - 300px);
    max-width: calc(90vw - 300px - 6em);
    height: 100%;
    object-fit: cover;

}

.sectionHeader {
    color: #555;
    font-size: 30px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom-style: solid;
    border-bottom-width: .2em;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 55px;
    align-items: center;
}

.sectionHeader h2 {
    margin: 0;
    margin-right: 10px;
    font-size: 30px;
    
}

.clickable {
    cursor: pointer;

}

.elevatorPitchVideoHolder {
    position: relative;
    z-index: 1000;
    margin: 11vw;
    margin-top: -7vw;
    margin-bottom: 2vw;
    width: 78vw;
    height: 44vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 1em !important;
    border-bottom-left-radius: 4em !important;
    border-top-right-radius: 4em !important;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);

}

.AboutUs {
    margin: 5vw;
    margin-top: 2vw;
    width: 90vw;
    display: flex;
    justify-content: center;
    margin-bottom: 2vw;
}

.AboutUsContent {    
    padding: 2em;  
    width: 90em;
    max-width: 95vw;
    border-radius: 1em !important;
    position: relative;
    overflow: hidden;
}

.AboutUsContentArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

}

.AboutUsContentAreaText {
    display: flex;
    flex-direction: column;
    padding-left: 2em;
    padding-top: 50px;
}

.AboutUsContentAreaText h2 {
    margin: 0px;
    margin-bottom: 1em;
    font-weight: 500;
}

.AboutUsContentAreaText h3 {
    margin: 0px;
    margin-bottom: 1em;
    font-weight: 400;
}

.react-swipeable-view-container > div > div > img {
    object-fit: cover !important;
}

#root > div > div > div > div.AboutUs > div > div.AboutUsContentArea > div:nth-child(1) > div > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.css-zjf1nq-MuiPaper-root {
    padding-left: 0px;
}


.Demo {
    margin: 5vw;
    /* margin-top: -5vw; */
    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 90vw;
    display: flex;
    justify-content: center;
}

.BlogSection {
    margin: 2vw 5vw;
    width: 90vw;
    display: flex;
    justify-content: center;
}

.BlogContentArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
    width: 100%;
}

.BlogPost {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    height: 200px;
    background-color: #eeeeee;
    border-radius: 3px;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    transition: all .3s ease-in-out;
    position: relative;
    opacity: 1;
    cursor: pointer;
    
}

.BlogPost:hover {
    opacity: .9;
}

.BlogPostImage {
    position: absolute;
    top: 0;
    width: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.BlogPostContentHolder {
    color: #ffffff;
    padding: 10px;
    padding-top: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 1;
    border-radius: 3px 3px 0px 0px;
}

.BlogTitle {
    margin: 0px;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.BlogPostContent {
    padding-top: 0px;
    margin: 0;
    font-size: 14px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ContactUs {
    margin: 5vw;
    width: 90vw;
    display: flex;
    justify-content: center;
}

.ContactUsContent {    
    padding: 2em;  
    width: 90em;
    max-width: 95vw;
    border-radius: 1em !important;
}

.ContactUsContentAreaText {
    display: flex;
    flex-direction: column;
}

.ContactUsContentAreaText h3 {
    margin: 0px;
    margin-bottom: 1em;
    font-weight: 400;
}

.sliderArea {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
    align-items: flex-start;
}

.getStartedFreeHolder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: column;
}

@media only screen and (max-width : 900px) {
    
    .sectionHeader {
        font-size: 20px;        
    }

    .sectionHeader h2 {
        margin: 0;
        margin-right: 10px;
        font-size: 20px;
        
    }

    .appBarHeaderLogo {
        height: 30px;
    }

    .HeroImg {
        height: 40vh;
    }

    .HeroImg div {
        background-color: rgba(0, 0, 0, .3);
    }

    .heroImgLogoBG {
        height: unset;
        width: 80%;
    }

    .heroImgLogo {
        height: unset;
        width: 80%;
    }
    
    .howItWorks {
        margin-bottom: 0vh;
    }

    .howItWorksContent {
        flex-direction: column;
        height: 140vw;
    }

    .howItWorksDataDiv {
        width: calc(90vw - 4em);
    }

    .howItWorksDataDiv h2 {
        width: 85%;
    }

    .sliderArea {
        width: calc(90vw - 4em);
    }

    .sliderArea p {
        margin: 0;
    }
    

    .howItWorksImgArea {
        border-bottom-left-radius: 2em;
        border-top-right-radius: 2em;
    }
    
    .howItWorksImgAreaShadow {
        border-bottom-left-radius: 2em;
        border-top-right-radius: 2em;
    }

    .howItWorksImgAreaShadow h1 {
        font-size: 1em;
    }

    .howItWorksPhoto {
        width: calc(90vw - 4em);
        max-width: calc(90vw - 4em);
    }

    .elevatorPitchVideoHolder {
        margin: 5vw;
        margin-top: -8vh;
        width: 90vw;
        height: 50.625vw;
        border-top-right-radius: 2em !important;
    }
    

    .AboutUs {
        margin-top: 5vw;
        margin-bottom: 5vw;
    }

    .AboutUsContent {
        margin-top: 0;
        width: calc(90vw - 4em);
        padding: 2em;
    }

    .AboutUsContentArea {
        flex-direction: column;
    }

    .AboutUsContentAreaText {
        padding-top: 0px;
        padding-left: 0em;
    }

    .Demo {
        margin-bottom: 5vw;
    }

    .BlogContentArea {
        flex-direction: column;
    }

    .BlogPost {
        margin: 0;
        margin-bottom: 10px;
        margin-top: 10px;
        min-height: 200px;

    }

    .BlogSection {
        margin: 5vw;

    }

}


@media only screen and (max-width : 600px) {

    .AboutUsContentAreaText {
        padding-left: '0px';
    }
    
    

}




