.contactUsContentArea {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.contactUsScroller {
    flex: 1;
    display: block;
    justify-content: center;
    overflow: auto;
    width: 1000px;
    max-width: calc(100% - 30px);
    margin: auto;
    padding: 1em;
}

.ContactUsContentPaper { 
    width: calc(100% - 4em);
    margin-top: 2em;
    padding: 2em;  
    max-width: 95vw;
    border-radius: 1em !important;
}

.ContactUsContentPaper h3 {
    display: flex;
    align-items: center;
    color: #555555;
    margin-bottom: 10px;
}



@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {


}




