.BlogContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.blogScroller {
    max-width: calc(100% - 100px);
    display: block;
    width: 1000px;
    margin: auto;
    background-color: #ffffff;
    padding: 30px;
    padding-top: 0px;
    border-radius: 0 0 3px 3px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blogBannerImg {
    width: 100%;
    align-content: center;
    object-fit: cover; 
    max-height: 500px;
    min-height: 300px;
    background-color: #eeeeee;
    border-radius: 0 0 3px 3px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.blogScroller h1 {
    margin-bottom: 10px;
}

.blogScroller h3 {
    margin-bottom: 0px;
    font-weight: 600;
}

.blogScroller h4 {
    margin-bottom: 0px;
    font-weight: 600;
}


.blogScroller h5 {
    font-weight: 300;
    font-size: 14px;
    margin: 0px;
}

p {
    line-height: 1.5em;
    margin-top: 10px;
    font-weight: 300;
}

.allBlogsButton {
    position: fixed !important;
    top: 75px;
    left: 15px;
}

.contactUsElement {
    max-width: calc(100% - 100px);
    display: block;
    width: 1000px;
    margin: auto;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 3px;
    margin-top: 15px;
    margin-bottom: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media only screen and (max-width : 1200px) {

}


@media only screen and (max-width : 600px) {

    .blogScroller {
        max-width: calc(100% - 60px);
        border-radius: 0px !important;
    }

    .contactUsElement {
        max-width: calc(100% - 60px);
        border-radius: 0px !important;
    }
}

