.paymentMethodElement {
    display: flex;
    padding: 15px;
    height: calc(220px - 0px);
    width: calc(400px - 0px);
    margin: 0px 30px 30px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.paymentMethodElementAction {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.paymentMethodElementCardInput {
    background-color: #fefefe;
    padding: 10px;
    border-radius: 3px;
    border-bottom: 1px solid #949494;
}

.paymentMethodCardElement {
    display: flex;
    padding: 15px;
    height: calc(220px - 30px);
    width: calc(400px - 30px);
    margin: 0px 30px 30px 0px;
    flex-direction: column;

}

.selectedPaymentMethod {
    border-style: solid;
    border-width: 3px;
    border-color: #f9a825;
    padding: 12px;
    height: calc(220px - 30px);
    width: calc(400px - 30px);

}

.paymentMethodHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.paymentMethodHeaderLogo {
    height: 30px;
}

.paymentMethodCardNumber {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 25px;
    flex: 1;
    letter-spacing: 6px;
    font-weight: 500;
}

.paymentMethodFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    font-weight: 500;
}

.paymentMethodContentArea {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.paymentMethodContentArea h4 {
    font-weight: 500;
}

.paymentMethodContentAreaCardHolder {
    display: flex;
    flex-direction: row;
}

.paymentMethodCardElementNoPadding {
    padding: 0px;
    min-height: 130px;


}

@media only screen and (max-width : 900px) {

    .paymentMethodElement {
        width: calc(100% - 30px) !important;
    }

    .paymentMethodCardElement {
        width: calc(100% - 30px);
        height: 130px !important;
        min-width: 260px;
    }

    .paymentMethodCardElementNoPadding {
        width: calc(100% - 0px) !important;
        min-width: 260px;
        height: 160px !important;
        padding: 0px;
    
    }

    .paymentMethodCardNumber span {
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 10px;
    };

    .paymentMethodFooter div {
        font-size: 18px;
    }

    .paymentMethodContentArea h4 {
        font-size: 18px;
        margin: 0px;
        margin-top: 5px;
    }

    .paymentMethodContentArea button {
        margin-top: 5px;
    }

    .selectedPaymentMethod {
        width: calc(100% - 30px);
        height: 130px !important;
    }

}