.blogContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.blogContentScroller {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: auto;
    width: 1000px;
    max-width: 100%;
    margin: auto;
}

.blogContentPaper {
    margin: 5px;
    margin-top: 15px;
    margin-bottom: 0px;
    max-height: calc(100% - 30px);
    width: calc(100% - 10px);
    position: relative;
    top: 0px;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: #0e0e0e;
    position: relative;
    top: 0px;
    
}


.blogContentPaper h2 {
    margin: 0px;
    margin-left: 10px;
    padding: 15px;
    padding-left: 10px;
    color: #555
}

.blogContentScroller .scrollerArea {
    padding: 15px;
    flex: 1;
    display: block;
    background-color: #e0e0e0;
    padding: 10px;
    padding-bottom: 0;
    overflow: auto;
    margin-left: 5px;
    width: calc(100% - 30px);
}


.blogElement {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 3px;
    cursor: pointer;
    overflow: hidden;

}

.blogImg {
    height: 80px;
    width: 120px;
    background-color: #eeeeee;
    border-radius: 3px;
    align-content: center;
}

.textArea {
    flex: 1;
    padding-left: 10px;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.postedData {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: .5em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.description {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 15px;
    margin-right: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;


}



@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {

    
    .blogElement {
        flex-direction: column;
        padding: 0px;
        position: relative;
        height: 200px;
    }

    .blogImg {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .textArea {
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
        height: calc(100% - 20px);
        width: calc(100% - 20px);
        position: absolute;
        top: 0;
        left: 0;
        justify-content: flex-end;
        color: #ffffff;
        transition: all 0.3s ease-in-out;
    }

    .textArea:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .textArea .title {
        font-size: 18px;
        font-weight: 600;
        
    }

    .textArea .postedData {
        background-color: #f9a825;
        color: #212121;
        font-size: 10px;
        font-weight: 400;
        padding: 5px;
        border-radius: 3px;
        align-self: flex-start;
    }

    .textArea .description {
        font-weight: 400;
        margin-bottom: 0px;
    }

    .title {
        font-size: 14px;
    }

    .postedData {
        font-weight: 500;
        font-size: 10px;
    }

    .description {
        font-size: 12px;
    }

    
}




