.gifPageContent {
    display: flex;
    flex-direction: row !important;
    height: 100%;
    flex: 1;
    overflow: hidden;
    position: relative;
    
}


.gifReviewDivSelectedArea {
  background-color: #ffffff;
  padding: 25px;
  height: 250px;
  border-bottom-style: solid;
  border-bottom-width: 5px;
  border-bottom-color: #f9a825;
}

.selectedAOIHeaderArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectedAOIHeaderAreaTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectedAOIArea {
  margin-top: 15px;
  height: 200px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;

}

.selectedAOIMetadataArea {
  padding-right: 35px;
  padding-left: 30px;
  width: 180px;
}

.selectedAOIPhotoArea {
  height: 200px;
  width: 300px;
  border-radius: 5px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; 
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.selectedAOIButtonArea {
  flex-direction: column;
  display: flex;
  margin-left: 15px;
  justify-content: space-around;
} 

.selectedAOIButton {

}

.gifReviewDiv {
  flex: 1;
  overflow: hidden;
}

.gifReviewDivScrollArea {
  flex: 1;
  overflow: auto;
  height: calc(100% - 305px);
}

.gifItem {
  width: 250px;
  height: 250px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 5px;
  float: left;
  cursor: pointer;
  border-color: #212121;
  border-style: solid;
  border-width: 4px;
}

.gifHolder {
  background-color: #ffffff;
  width: 250px;
  height: 190px;
  border-radius: 3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.gifItemButtons {
  display: flex;
  flex-direction: row;
  height: 60px;
  margin-top: 5px;
  align-items: center;
  justify-content: space-around;

}

.gifMapView {
  flex: 1;
}

.gifMapContainer {
  height: 100%;
  width: 100%;
}

img[src*="firebase"] {
  filter: brightness(3);
}


