.appBar {
    position: absolute !important;
    height: 60px;
    z-index: 100;
    margin: 0;
}

.drawerHeader {
    padding: 15px;
    border-radius: 0px !important;

}

.drawerHeader div {
    display: flex;
    flex-direction: row;
}

.drawerHeader div .appBarHeader{
    flex-direction: column;
}

.drawerHeader h5 {
    margin: 0;
    color: #ffffff;
    font-size: 20px;
    font-weight: 500;
}

.appBarHeaderLogo {
    height: 35px;
    margin-right: 10px;
}

.appBarHeader {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.css-hyum1k-MuiToolbar-root {
    height: 60px;
}

.navbarOrganizationPicker {
    margin-left: 15px !important;
    min-width: 140px !important;
}

.navbarOrganizationPicker label {
    color: #ffffff !important;
    
}

.navbarOrganizationPicker div svg {
    color: #ffffff !important;
}


.navbarOrganizationPicker div fieldset {
    border-color: #ffffff !important;
}

