.adminHRContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.adminHRContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.adminHRContentPaper {
    margin: 15px;
    padding: 30px;
}

.adminHRContentPaper h1 {
    margin-top: 0px;
}

.adminHRInfoArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
}

.adminHRContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}

.adminHRContentArea {
    padding-top: 15px;
}

.adminHRContentUserArea {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 215px;

}

.adminHRContentUser {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 180px;
    height: 170px;

}

.adminHRContentUser img {
    height: 75px;
    width: 75px;
    background-color: #eeeeee;
    border-radius: 100px;
    margin-bottom: 10px;
}

.adminHRContentUser h2 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    
}

.adminHRContentUser h4 {
    margin: 0px;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.adminHRContentUser p {
    margin: 0px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.adminHRInfoProfileImg {
    height: 50px;
    width: 50px;
    background-color: #eeeeee;
    border-radius: 100px;
    align-content: center;
}

.adminHRInfoAreaTextHolder {
    display: block;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
    width: calc(100% - 120px);
}

.adminHRInfoAreaTextHolder {
    margin-top: 0px;
    margin-bottom: 5px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminHRInfoAreaTextHolder h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminHRInfoAreaTextHolder h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminHRInfoAreaTextHolder p {
    margin: 0px;
    font-size: 12px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminHRContentArea {
    padding-top: 15px;
}