
.App {
    height: 100vh;
    width: 100vw;

 }

 .AppContent {

    height: calc(100% - 60px);
    width: 100vw;
    padding-top: 60px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
 }

 .AppContent::-webkit-scrollbar {
    display: none !important;
    width: 0px;
 }

.introjs-overlay::-webkit-scrollbar {
   display: none !important;
   width: 0px;
}

.introjs-overlay {
   -ms-overflow-style: none !important;  /* IE and Edge */
   scrollbar-width: none !important;  /* Firefox */
}

body {
   height: 100vh;
   width: 100vw;
   overflow: hidden;
}

.link {
  color: #1976D2 !important;
  cursor: pointer;
}

.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.5);
}

.appAlertHolder {
  position: fixed;
  bottom: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  
}

.appAlert {
  max-width: calc(100% - 80px);
  width: 1000px;

}

.footerLegal {
  text-align: right;
}

.footerLegal p {
  margin: 0px;
  margin-bottom: 5px;
}

.shown-mobile {
  display: none !important;
}


 @media only screen and (max-width : 1200px) {
   
   .footerLegal {
      padding-right: 1em;
   }

 }


 @media (max-width: 900px) {
  
    .App {
      background-color: #ffffff;
    }

   .hidden-mobile {
     display: none !important;
   }
 
   .shown-mobile {
     display: flex !important;
   }
 
   .siteNotDesignedForMobileMessage {
     display: flex !important;
   }

   .AppContent {
    height: calc(100% - 60px);
   }
 }

 @media screen and (min-width: 320px) and (max-width: 900px) and (orientation: landscape) {
   html {
     transform: rotate(-90deg);
     transform-origin: left top;
     width: 100vh;
     overflow-x: hidden;
     position: absolute;
     top: 100%;
     left: 0;
   }
 }