

.adminMapContainerDiv {
  flex: 1;
  flex-direction: column;
  display: flex;
  position: relative;
}

.adminMapViewBody {
  flex: 1;
}

.adminMapContainerSliderDiv {
  height: 0px;
  transition-duration: 0.5s;
  background-color: #ffffff;
  padding-right: 60px;
  padding-left: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.adminMapDateSlider {
  position: relative;
}

.adminMapLeftAlignedActions {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  flex-direction: column;
  z-index: 200;

}

.adminMapSelectAnalyticCard {
  display: flex;
  flex-direction: column;
  min-height: 45px;
  min-width: 240px;
  padding: 0px;
  overflow: hidden;
}

.adminMapSelectAnalyticImagePickerAreaHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.adminMapSelectAnalyticImagePickerAreaHeader h5 {
  margin-bottom: 0px !important;
  margin-right: 10px !important;
}

.adminMapSelectAnalyticImagePickerArea {
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 200px;
}

.labelPickerArea button {
  margin-bottom: 15px;
}

.adminMapSelectAnalyticCardMapSelector {
  display: flex;
  flex-direction: row;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dddddd;
  

}

.adminMapSelectAnalyticCardSquareInfo {
  padding: 10px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
}

.adminMapSelectAnalyticCardSquareInfo h5 {
  margin: 0px;
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

}

.adminMapSelectAnalyticCardSquareInfo h5 img {
  width: 24px;
  height: 24px;
  margin-right: 0px;
  border-radius: 50%;
}

.adminMapSelectAnalyticCardSquareInfo h5 span {
  font-weight: 400;
  padding-left: 10px;
  max-width: 175px;
}

.adminMapSelectAnalyticCardSquareInfo h5 span div {
  display: flex;
  flex-direction: column;

}

.adminMapSelectAnalyticCardSquareInfo h5 span div div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.adminExportImageryCard {
  display: flex;
  flex-direction: column;
  min-height: 45px;
  min-width: 220px;
  padding: 0px;
  overflow: hidden;
  margin-top: 10px;
  padding: 10px;
}

.adminExportImageryCard h3 {
  margin: 0px;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;
}

.adminExportImageryDateSelector {
  display: flex;
  flex-direction: column;
  width: 160px;
  margin-bottom: 15px;
}

.adminMapSaveAnalyticCard {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 10px;
  right: 8px;
  padding: 5px;
  z-index: 200;
}

.adminMapExitAnalyticCard {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 100px;
  padding: 5px;
  left: 10px;  
  z-index: 200;
}

.mapTaskingData {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.mapTaskingData h3 {
  margin: 0px;
  font-size: 16px;
  margin-bottom: 5px;
}