img[src*="firebase"] {
  z-index: 2000;
}

.mapViewContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.mapViewHeader {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    padding: 5px 20px;
    height: 60px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.mapViewHeaderLotPicker {
    min-width: 200px;
    margin-right: 20px !important;
}

.mapViewHeaderLotPickerSelect {
  min-width: 200px;
}

.mapViewBody {
  flex: 1;
}

.mapViewBodyLoading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expandmenu {
   position: absolute;
    top: 130px;
    left: 5px;
    z-index: 1000;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapViewActionsDiv {
  position: absolute;
  min-width: 200px;
  top: 80px;
  left: 5px;
  padding: 0px;
  z-index: 1000;
}

.mapViewCardMapSelector {
  display: flex;
  flex-direction: row;
  
}

.mapViewActionsDivContent {
  padding: 10px;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dddddd; 
  display: flex;
  flex-direction: column;
}


.mapViewFooter {
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
}

.mapViewFooterDateSliderArea {
  width: 100%;
  height: 40px;
  flex: 1;
  position: relative;
}


@media (max-width: 900px) {
  
  .mapViewContent {
    height: calc(100% - 50px);
  }
 }