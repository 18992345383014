/* @font-face {
    font-family: "Nunito";
    src: url("./assets/Fonts/Nunito/nunito-variablefont_wght-webfont.woff") format("woff");
} */

body {
    margin: 0;
    font-family: Roboto !important;
    /* font-weight: 500; */
    background-color: #e0e0e0;
}

a {
    text-decoration: none;
    color: inherit;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }

/* Track */
::-webkit-scrollbar-track {
background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
background: #555; 
}



@media only screen and (max-width : 600px) {
    /* width */
    ::-webkit-scrollbar {    
        display: none;
    }

}