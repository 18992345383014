.viewLotContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.viewLotContent::-webkit-scrollbar {
    display: none !important;
    width: 0px;
}

.viewLotContentScroller {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.viewLotContentPaper {
    margin: 15px;
    margin-bottom: 50px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    
}

.viewLotContentPaper h1 {
    margin-top: 0px;
}

.viewLotContentBody {
    display: block;
    width: 100%;
}

.viewLotLotCard {
    padding: 15px;
    margin: 15px;
    width: 300px;
}

.viewLotTabPannel {
    width: 100%;
    flex: 1;
}

.viewLotGeneralTabScrollArea {
    width: 100%;
    overflow: auto;
    padding-top: 15px;
    padding-bottom: 10px;
}

.viewLotMapContainer {
    height: 400px;
    max-height: 50%;
    width: 100%;
    background-color: #eeeeee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    margin: 15px;   
    margin-left: 0px;
    margin-right: 0px;
}

.viewLotButtonHolder {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.viewLotRefeshButtonHolder {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

}

.viewLotTabPannelBilling {
    padding-left: 15px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.createLotPaymentOptionsAreaHolder {
    display: flex;
}


@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {
   

}




