.pricingContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
}

.pricingContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.pricingContentPaper {
    margin: 15px;
    padding: 30px;
}

.pricingContentPaper h1 {
    margin-top: 0px;
}

.pricingOptionsArea {
    display: flex;
    flex-direction: row;

}

.pricingOption {
    flex: 1;
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    overflow: hidden;
    border-style: solid;
    border-width: 1px;
    border-color: #dddddd;
    display: flex;
    flex-direction: column;

}

.pricingOption h1 {
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
}

.pricingOption h3 {
    font-weight: 200;
    text-align: center;
    margin-bottom: 0px;
    font-size: 14px;
}

.pricingOption h2 {
    font-weight: 200;
    text-align: center;
    margin-bottom: 0px;
    font-size: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
}

.pricingOption h2 span {
    font-size: 20px;
    margin-left: 5px;
    font-weight: 100;
}

.pricingOption h4 {
    height: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 40px;
    font-size: 14px;
}

@media only screen and (max-width : 800px) {

    .pricingOptionsArea {
        flex-direction: column;
    }

}





