.organizationContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.organizationContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.organizationContentPaper {
    margin: 15px;
    padding: 30px;
    display: flex;
    flex-direction: column;
}

.organizationContentPaper h1 {
    margin-top: 0px;
}

.organizationTabContent {
    display: flex;
    flex-direction: column;
}

.organizationInfoArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
}

.organizationContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}

.organizationContentArea {
    padding-top: 15px;
}

.organizationContentUserArea {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 215px;

}

.organizationContentUser {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 180px;
    height: 170px;

}

.organizationContentUser img {
    height: 75px;
    width: 75px;
    background-color: #eeeeee;
    border-radius: 100px;
    margin-bottom: 10px;
}

.organizationContentUser h2 {
    font-size: 18px;
    margin: 0px;
    margin-bottom: 10px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    
}

.organizationContentUser h4 {
    margin: 0px;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.organizationContentUser p {
    margin: 0px;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.organizationInfoProfileImg {
    height: 50px;
    width: 50px;
    background-color: #eeeeee;
    border-radius: 100px;
    align-content: center;
}

.organizationInfoAreaTextHolder {
    display: block;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
    width: calc(100% - 120px);
}

.organizationInfoAreaTextHolder {
    margin-top: 0px;
    margin-bottom: 5px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.organizationInfoAreaTextHolder h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.organizationInfoAreaTextHolder h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.organizationInfoAreaTextHolder p {
    margin: 0px;
    font-size: 12px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.organizationContentArea {
    padding-top: 15px;
}

.organizationContentPaymentMethodArea {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

@media only screen and (max-width : 1200px) {
    

}


@media only screen and (max-width : 600px) {
    .organizationInfoProfileImg {
        height: 50px;
        width: 50px;
    }

    .organizationInfoAreaTextHolder {
        margin: 0px;
    }

    .organizationInfoAreaTextHolder h2 {
        font-size: 16px;
        margin: 0px;
    }

    .organizationInfoAreaTextHolder h4 {
        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .organizationInfoAreaTextHolder p {
        font-size: 12px;
        margin: 0px;
        font-weight: bold;
    }

}




