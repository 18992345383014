.footer {
    width: 100vw;
    float: left;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
    background-color: #212121;
    color: #f5f5f5;
}

.footerDivider {
    width: calc(100% - 30px);
    height: 3px;
    background-color: #f5f5f5;
    margin: 15px;
    float: left;
}

.footerAction {
    float: left;
    margin: 15px;
    margin-top: 0;
}


.footerTitleHolder {
    float: left;
}

.footerIcon {
    margin-left: 1em;
    width: 3em;
    float: left;
}

.footerLogo {
    height: 40px;
    padding-bottom: 10px;
}

.footerTitle {
    color: #fff;
    padding-left: .15em;
    height: 40px;
    line-height: 1em;

    font-weight: bold;
    text-decoration: none;
}
.footerSubTitle {
    color: #fff;
    padding-left: .25em;
    font-size: 12px;
    text-decoration: none;
}

.footerContent {
    float: left;
    display: flex;
    flex-direction: row;
    width: 97.5vw;
}



.footerLegal {
    font-size: 12px;
}

@media only screen and (max-width: 900px) {      
    
    .footerDivider {
        margin: 5px;
        height: 1px;
    }


    .footerLogo {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    .footerTitleHolder {
        padding-top: 0.1em;
    }
    
    .footerIcon {
        width: 1.5em;
    }
    
    .footerTitle {
        padding-left: .2em;
        font-size: 1.25em;
        line-height: .75em;
        height: .75em;
     }
    .footerSubTitle {
        padding-left: .25em;
        font-size: .5em;
    }
    
 
    .footerLegal {
        font-size: .5em;
    }
    

}

@media only screen and (max-width : 350px) {   

    .footerTitleHolder {
        padding-top: 0.4em;
    }

    .footerIcon {
        width: 1.5em;
    }
    

    .footerTitle {
        padding-left: .1em;
        font-size: 1em;
        line-height: .6em;
        height: .6em;
     }
    .footerSubTitle {
        padding-left: .15em;
        font-size: .4em;
    }
    
 
    .footerLegal {
        font-size: .4em;
    }
 
}