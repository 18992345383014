.adminTaskingContent {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.adminTaskingContentScroller {
    flex: 1;
    display: block;
    justify-content: center;
    width: 1000px;
    max-width: 100%;
    margin: auto;

}

.adminTaskingContentPaper {
    margin: 15px;
    padding: 30px;
    margin-bottom: 100px;
}

.adminTaskingContentPaper h1 {
    margin-top: 0px;
}

.adminTaskingInfoArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 15px;
}

.adminTaskingContentHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.adminTaskingContentArea {

}

.adminTaskingContentTaskArea {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    min-height: 300px;
    

}

.adminTaskingContentTask {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: 15px;
    margin-bottom: 15px;
    width: 200px;
    height: 250px;
    background-color: #ffffff !important;

}



.adminTaskingContentTask h3 {
    margin: 0px;
    font-size: 16px;
    margin-bottom: 5px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines you want */
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    height: 45px;
}

.adminTaskingContentTaskData {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 0px;
    margin-bottom: 0px;
}

.adminTaskingContentTaskData h5 {
    margin: 0px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;

}

.adminTaskingContentTaskData p {
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminTaskingContentTaskDataAnalystHolder {
    flex: 1;
    padding-top: 5px;
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    flex-wrap: wrap;
    height: 65px;
    overflow-y: auto;
}

.submitChangesForReviewButtonHolder {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    width: 100%;
    min-height: 36px;
}

.submitChangesForReviewButtonHolder h3 {
    margin: 0px;
    margin-left: 10px;
    text-align: center;
    height: auto;
}

.adminTaskingInfoProfileImg {
    height: 50px;
    width: 50px;
    background-color: #eeeeee;
    border-radius: 100px;
    align-content: center;
}

.adminTaskingInfoAreaTextHolder {
    display: block;
    flex: 1;
    flex-direction: column;
    padding-left: 20px;
    width: calc(100% - 120px);
}

.adminTaskingInfoAreaTextHolder {
    margin-top: 0px;
    margin-bottom: 5px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminTaskingInfoAreaTextHolder h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminTaskingInfoAreaTextHolder h4 {
    margin-top: 5px;
    margin-bottom: 10px;
    width: calc(100% - 0px);
    display: -webkit-box;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.adminTaskingInfoAreaTextHolder p {
    margin: 0px;
    font-size: 12px;
    width: calc(100% - 0px);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
